import React from "react"
import PropTypes from "prop-types"
import TheaterVideo from "@components/Theater/TheaterVideo"
import Image from "../../components/Image"
import Text from "../../components/Text"
import { Section } from "../../components/Containers"

function TopSection(props) {
  const { language, post } = props
  return (
    <Section>
      <div className="columns">
        <div className="column is-4" />
        <div className="column">
          <Text text={post.topBlurb} />
          <Text className="mt-2" text={post.ourMission} />
        </div>
        <div className="column is-4" />
      </div>

      {post.mainVideo.youtube && (
        <div className="columns has-text-centered about-main-theater-image">
          <div className="column is-full-width">
            <TheaterVideo
              buttonClass="contained"
              videoUrl={`https://www.youtube.com/watch?v=${post.mainVideo.youtube}`}
              controls
              playing
              overVideo
              language={language}>
              <Image
                wrapperClassName="show-desktop-only"
                publicId={post.mainVideo.imageId}
                useAR
              />
              <Image
                wrapperClassName="show-desktop-only-inverse"
                publicId={post.mainVideo.imageIdMobile}
                useAR
              />
            </TheaterVideo>
          </div>
        </div>
      )}
    </Section>
  )
}

TopSection.propTypes = {
  post: PropTypes.object.isRequired,
  language: PropTypes.string
}

export default TopSection
